/* Loader.css */

.aligner{
    text-align: center;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
  
  .progress-bar {
    width: 80%;
    max-width: 400px;
    background-color: #303030;
    border-radius: 10px;
    overflow: hidden;
    margin: 30px 40px;
  }
  
  .progress {
    height: 20px;
    width: 0;
    background-color: #A35EFF;
    transition: width 0.7s ease-out; /* Add a transition for the progress bar */
  }
  
  .loader-overlay.hidden {
    opacity: 0;
    pointer-events: none;
  }
  